import {useEffect} from "react";
import Header from "../components/Header2";
import Footer from "../components/Footer2";
export default function PrivacyPolicy() {

useEffect(() => {
   document.title = "Privacy Policy - 10xcoder"
}, []);

return (
<>
<Header />

	<div className="container">
		<div className="row mt-5">
			<div className="col text-center mt-5" style={{paddingBottom:"24px"}}>
				<h2 style={{fontWeight: "600"}}>Privacy Policy</h2>
			</div>
		</div>
		<div className="row">
			<div className="col-md-12" style={{color: "#777", marginBottom: "96px"}}>
		<p>10xcoder services (“Company” or “we” or “us”), is the owner of the application titled 10xcoder stack (collectively referred as “Platform”). This page informs a visitor or user (“you”, “your” or “User”) of the Platform of our policies regarding the collection, use, and disclosure of personal data when you use our Platform and the choices you have associated with that data (hereinafter referred to as “Privacy Policy”). We use your data to provide and improve the Platform. By using the Platform, you agree to the collection and use of information in accordance with this Privacy Policy</p>


    	<p>INTRODUCTION AND APPLICABILITY OF THE PRIVACY POLICY</p>
        
      <p>We are strongly committed to respecting your online privacy and recognize the need for appropriate protection and management of any personal information collected and/or collated by us. The purpose of this Privacy Policy is to ensure that there is an intact charter that governs the collection, usage and protection of any personal and/or sensitive data collected by us. This Policy defines our procedure for collection, usage, processing, disclosure and protection of any data or information obtained by us through the Platform. Any reference made to Privacy Policy in this document shall mean and refer to the latest version of the Privacy Policy</p>

    	<p>DISCLAIMER</p>
      <p>Please be advised that any Information (as defined herein below) procured by us, shall be:
            processed fairly and lawfully for rendering the Services (as defined in the Terms of Service);
            obtained only for specified and lawful purposes, and not be used in any manner which is against the law or policy in force in India (“Applicable Law”)
            adequate, relevant and not excessive in relation to the purpose for which it is required
            able to be reviewed by the User, from time to time and updated-if need arises; and
            not kept longer than for the time which it is required or the purpose for which it is required or as required by the applicable law</p>

       <p>WE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE SUSTAINED BY REASON OF ANY DISCLOSURE OF YOUR INFORMATION AND DATA (INADVERTENT OR OTHERWISE), IF THE SAME IS EITHER (A) REQUIRED FOR LEGITIMATE PURPOSES; OR (B) WAS EFFECTED THROUGH NO FAULT, ACT, OR OMISSION OF THE COMPANY
        BY USING THE PLATFORM OR SERVICES, YOU EXPLICITLY ACCEPT, WITHOUT LIMITATION OR QUALIFICATION, THE COLLECTION, USE AND TRANSFER OF THE INFORMATION IN THE MANNER DESCRIBED HEREIN
        PLEASE READ THIS PRIVACY POLICY CAREFULLY AS IT AFFECTS YOUR RIGHTS AND LIABILITIES UNDER LAW</p>

    <p>Your consent</p>
     <p>Please note that by providing the Information (as defined herein below), you provide your unconditional consent and authorize us to collect, use or disclose such Information for the business and research purposes and as stated in this Privacy Policy and as permitted or required by applicable law. Moreover, you understand and hereby consent that this Information may be transferred to any third party for the purpose of providing Services through the Platform or to any third-party providers for rendering Services, for any jointly developed or marketed services, for payment processing, for order fulfilment, customer services, data analysis, information technology services and such other services which enable us to provide Services through the Platform or otherwise
        This Privacy Policy shall be enforceable against you in the same manner as any other written agreement. By visiting or accessing the Platform and voluntarily providing us with Information (including Personal Data), you are consenting to our use of the Information, in accordance with this Privacy Policy. If you do not agree with this Privacy Policy, you may refuse or withdraw your consent any time, or alternatively choose to not provide us with any Information. Under such circumstance, we may be unable to render Services.
        Such an intimation to withdraw your consent can be sent to [team@10xcoder.com]</p>

    <p>TYPES OF DATA COLLECTED</p>

    <p>We collect several different types of information for various purposes to provide and improve our Platform to you<br/>
        “Personal Data”, means and includes any Information that relates to a natural person through which an individual is identified, such as the name, contact details, email address, gender, age, organization, employment status, employer details, designation, income and years of experience and any other information pertaining to a User that is shared in the course of availing the Services<br/>
        “Sensitive Personal Data” means and includes information relating to; (i) government ID cards; (ii) financial information relating to such as bank account or credit card or debit card or other payment instrument details, credit score; (iii) any detail relating to the above-mentioned points as provided to us for using the Platform and (iv) Transaction Information.<br/>
        “Transaction Information” means information that is contained in text messages (that is, SMS) sent by providers of services and/or products (including but not limited to financial institutions, mobile carriers and utility companies) to your mobile telephone number(s) in connection with one or more transactions across multiple financial accounts (including purchase, payments, billing information and service notifications related thereto)<br/>
        “Technical Information” means and includes any Information gathered through various technologies that may employ cookies, web beacons, or similar technologies to automatically record certain information from your device through which you use the Platform. This technical information may include your Internet Protocol (IP) address, device or browser type, Internet service provider (ISP), referring or exit pages, clickstream data, operating system, hardware model, operating system version, unique device identifiers, and mobile network. This data includes usage information and user statistics.<br/>
        “Locational Information” shall mean and include the information obtained through GPS or other means, such as the geographical location of the User<br/>
        “Non-Personal Information” means and includes any information that does not reveal your specific identity, such as, browser information, information collected through Cookies (as defined below), pixel tags and other technologies, demographic information, etc. As is true with most websites, our Company gathers some information automatically when you visit the Platform. When you use the Platform, we may collect certain information about your computer or mobile to facilitate, evaluate and verify your use of the Platform. For example, we may store environmental variables, such as browser type, operating system, speed of the central processing unit (CPU), referring or exit web pages, click patterns and the internet protocol (IP) address of your computer. This information is generally collected in aggregate form, without identifying any user individually.

    The above-mentioned information shall be collectively referred to as “Information”</p>

    <p>USE OF DATA</p>

    <p>
    In addition to the uses mentioned in clause 3.1, the Information collected by us shall be used for following functions including but not limited to:
        to render Services;
        to evaluate the quality and competence of our personnel;
        to resolve any complaints, you may have and ensure that you receive the highest quality of Services
        notifying you about changes to our Platform;
        providing analysis or valuable information so that we can improve the Platform;
        monitoring and maintaining the usage of the Platform;
        sending out system updates on the booked services,
        detecting, preventing and addressing technical issues;
        Analyse usage patterns;
        Improve user experience;
        re-marketing campaigns (SMS, email, PN campaigns);
        targeted marketing campaigns, periodic follow-ups
        Notify you about new services and features.</p>

    <p>Business or Research Purposes: The Information, Personal Information, will be used for business or research purposes, including improving and customizing the Platform for ease of use and the services offered by us. We may archive this information to use it for future communications for providing updates and/or surveys.</p>

    <p>Aggregating Information / Anonymized data: We may aggregate Information and analyse it in a manner to further improve the level of services that we offer to our customers. This Information includes average number of Users of the Platform, the average clicks of the services/, the features used, the response rate, etc. and other such statistics regarding groups or individuals.</p>

    <p>DISCLOSURE AND SHARING OF INFORMATION:</p>

    <p>We do not rent, sell or disclose or share any Information that we collect from you, with third parties, save and except in order to provide you with the Services. Any such disclosure, if made, shall be in accordance to this Privacy Policy and as per the procedure prescribed by law and in compliance with our legal obligations. We may share your Information in circumstances and for the purposes as specified hereunder:
        We shall share the information to the third-party service providers/ vendors, to provide you with the Services. This may include but not be limited to Lenders (as defined in the Terms), storage providers, data analytics providers, consultants, lawyers, providers of various services and auditors
        When compelled by law, we may disclose any Information provided by you on the Platform as may be deemed to be necessary or appropriate:
            under applicable law, including laws outside your country of residence;
            to comply with legal process;
            to respond to requests from public, Regulatory and government authorities including public and government authorities outside your country of residence;
            to protect our operations or those of any of our affiliates;
            to protect our rights, privacy, safety or property, and/that of our affiliates, you or others;
            to allow us to pursue available remedies or limit the damages that we may sustain; or
            to protect against legal liability;
            to protect the personal safety of Users of the Platform;
            to prevent or investigate possible wrongdoing in connection with the Platform.
        Merger or Acquisition: We may share Information upon merger or acquisition of Company with another company, which shall automatically acquire the rights and obligations as per the Policy. We shall transmit and transfer the Information upon acquisition or merger of Company with another company
        With our service providers: We may share Information with other service providers on a need- to-know basis, subject to obligations of confidentiality for provision of Services. We hereby clarify that Company works with institutions, vendors, partners, advertisers, and other service providers, including (but not limited) to those who provide services such as contact Information verification, website hosting, data analysis, providing infrastructure, information technology services, auditing services and other similar services, in different industries and categories of business by virtue of lawful contracts instituted between such third parties and Company to improve our services. Accordingly, we may share your Information with such service provider as reasonably necessary to provide you with Services.

    We may, at our option, remove parts of data that can identify you and share anonymized data with other parties. We may also combine your information with other information in a way that it is no longer associated with you and share that aggregated information

    Except for the Information disclosed pursuant to sub-clause (a), (b), (c), (d) and (e) of Clause 6.1 above, Company may share Information only if you authorize us to do so</p>

    <p>TRANSFER OF DATA</p>
        <p>Your Information may be transferred to, and maintained on, computers located in India, and will be governed by the Indian Data Protection Laws.
        If you are located outside India and choose to provide information to us, please note that we may transfer the data to India to process the Information.
        Your consent to this Privacy Policy followed by your submission of such information represents your unconditional agreement to that transfer or use.
        We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your data will take place to an organization or a country unless there are adequate controls in place including the security of your data.</p>

    <p>YOUR RIGHTS</p>

    <p>You can always choose not to provide the requested information to us, it may however result in You not availing certain features or the entire set of our Services. You retain several rights in relation to your Personal Data as provided under applicable law. These may include the rights to:
        access, confirm, and review Personal Data you may have provided;
        correct Personal Information that may be inaccurate or irrelevant;
        deletion and erasure of your Personal Data from the publicly available pages of the Platform;
        receive Personal Information we hold about you in a portable format;
        object to or restrict any form of processing you may not be comfortable with. In order to exercise these rights, please contact us on the email address provided in Clause 3.3 above.</p>

    <p>If you want to withdraw your consent or raise any objection to the use of your information for receiving any direct marketing information to which you previously opted-in, you can do so by contacting our customer support at above mentioned addresses. If you withdraw/object your consent, our use of your information before you withdrew/objected shall still be lawful.</p>

    <p>APPLICATIONS USED</p>
        <p>You may be availing our services for using third party mobile applications, which are not operated by us. We strongly advise you to review the privacy policy of every mobile application which you may use and we shall not be answerable/responsible for the actions/inactions/use of your data by third parties
        We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party mobile applications or services</p>

    <p>CHILDREN’S PRIVACY</p>

    <p>Our Platform and Services are not meant for use by children and we knowingly do not collect Information of/from children. If it comes to our notice that we have collected Information from/of children, we shall take steps to remove such Information from our servers. If you believe that we might have any Information that may have been collected from a child or has been provided by a child, please write to us at the email id provided in Clause 3.3</p>

    <p>CONTROL THE COLLECTION OR USE OF THE INFORMATION</p>

    <p>If you have any reservations, constraints or apprehensions regarding the access to, collection, storage, or any other use of the Information which you have provided to us, you may withdraw your consent by writing to us on the email provided as hereinabove.</p>

    <p>RETENTION OF INFORMATION</p>

    <p>All Information provided by you, save and except upon withdrawal or termination, may be retained in locations outside the direct control of Company (for instance, on servers or databases co-locates with hosting providers). We will delete Information based on a request received from you within a reasonable period and latest within 90 (ninety) days of receiving a deletion request. However, we may retain such portion of Information and for such periods as may be required under Applicable Law. Notwithstanding anything contained herein, Company may retain data after account deletion for reasons including but limited to the following purposes: If there is an unresolved issue relating to your account, or an unresolved claim or dispute; If we are required to by applicable law; and/or in aggregated and/or anonymized form; or Company may also retain certain information if necessary for its legitimate business interests, such as fraud prevention and enhancing Users' safety and security</p>

    <p>COOKIES AND OTHER TRACKING TECHNOLOGIES</p>

    <p>Our Platform may utilize “cookies” and other Technical Information. “Cookies” are a small text file consisting of alphanumeric numbers used to collect the Information about Platform activity. The Technical Information helps us analyse web traffic and helps you by customizing the Platform to your preferences. Cookies in no way gives us access to your computer or mobile device. In relation to Cookies, you can deny access to the installation of the Cookies by modifying the settings on your web browser, however, this may prevent you from taking full advantage of the Platform. Our use of Cookies and Technical Information allows us to improve Platform and your experience of Platform and Services. We may also analyse Technical Information that does not contain Personal Information for trends and statistics.</p>

    <p>THIRD PARTY SERVICES</p>

    <p>We may use your Information to send you promotional Information about third parties which, we think you may find interesting, if you tell us that you wish this to happen. We shall not be responsible for any disclosure of Information due to unauthorized third-party access or other acts of third parties or acts or omissions beyond our reasonable control and you agree that you will not hold us responsible for any breach of security unless such breach has been caused as a direct result of our negligence or wilful default</p>

    <p>Once you leave the Platform, we are not liable for any use/ storage/ processing/ collection of your information obtained by any third-party websites or payment facilitators or provided by you to these third-parties or payment facilitators. Such entities and their respective websites/platforms may be governed by their own “Privacy Policy” and “Terms of Service”, which are beyond our control.</p>

    <p>DATA SECURITY</p>

    <p>You agree and accept that your Information may be stored in third-party cloud service infrastructure providers. While all reasonable attempts have been taken from our end to ensure the safe and secure storage of your data, we shall not be liable for any data breach on the part of the third-party cloud service infrastructure provider that was beyond our control. In addition to the security measures put in place by the third-party cloud service infrastructure provider for safe and secure storage of your Information, we use certain physical, managerial, technical or operational safeguards as per industry standards and established best practices to protect the Information we collect. We use reasonable security practices and procedures and use secure servers as mandated under applicable laws for the protection of your Information. We review our Information collection, storage, and processing practices, including physical security measures to guard against unauthorized access to systems. However, as effective as these measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that the Information you supply will not be intercepted while being transmitted to us over the internet. You accept the inherent security implications of data transmission over the internet and the internet cannot always be guaranteed as completely secure. Therefore, your use of the Platform will be at your own risk. If you have any concerns, please feel free to contact us at the details given in Clause 3.3 above.</p>

    <p>CHANGES AND UPDATES TO POLICY</p>

    <p>We may modify or revise the Privacy Policy from time to time and shall accordingly notify you of any changes to the Privacy Policy by posting the revised Privacy Policy on the Platform with an updated date of revision. We shall endeavour to review, revise, update, modify, amend or correct the Privacy Policy on a regular and routine basis, especially whenever a significant update is made to the technology employed by us. You must periodically review the Privacy Policy for the latest information on our privacy practices. In the event you continue to use the Platform and our services after any update in the Privacy Policy, your use of the Platform shall be automatically subject to such updated privacy policy, without any requirement on our part to give a specific notice thereof. Your continued usage of Services or access of the Platform post any amendment would deem to mean that you accept and agree to the revised Privacy Policy. Further, we retain the right at any time to deny or suspend access to all, or any part of, the service to anyone who we reasonably believe has violated any provision of this Privacy Policy</p>

    <p>DISCREPANCIES AND GRIEVANCES WITH COMPANY</p>

    <p>We assure you that we shall ensure implementation of the Privacy Policy and shall make the Privacy Policy available to Users. We will acknowledge each grievance that is received within 24 hours and put our best efforts to redress the grievances of the User expeditiously within fifteen (15) days from the date of receipt of the grievance. The User agrees and acknowledges that the Company shall address and attempt to resolve the complaint received in accordance with the standard policies and procedures adopted by the Company, the User’s disapproval/discontent with the outcome/mode of redressal shall not be deemed to mean non-redressal of the grievance by the Company. Any suggestions by Company regarding use of the Service shall not be construed as a warranty</p>

    <p>Please feel free to reach out to us by team@10xcoder.com in case of any concerns, grievances, or questions relating to our privacy or data related practices.</p>

    <p>MISCELLANEOUS</p>

    <p>THE INVALIDITY OR UNENFORCEABILITY OF ANY PART OF THIS PRIVACY POLICY SHALL NOT PREJUDICE OR AFFECT THE VALIDITY OR ENFORCEABILITY OF THE REMAINDER OF THIS PRIVACY POLICY. THIS PRIVACY POLICY DOES NOT APPLY TO ANY INFORMATION OTHER THAN THE INFORMATION COLLECTED BY US THROUGH THE PLATFORM. THIS PRIVACY POLICY SHALL BE INAPPLICABLE TO ANY UNSOLICITED INFORMATION YOU PROVIDE US THROUGH THE PLATFORM OR THROUGH ANY OTHER MEANS. ALL UNSOLICITED INFORMATION SHALL BE DEEMED TO BE NON-CONFIDENTIAL AND WE SHALL BE FREE TO USE AND/ OR DISCLOSE SUCH UNSOLICITED INFORMATION WITHOUT ANY LIMITATIONS. THE RIGHTS AND REMEDIES AVAILABLE UNDER THIS POLICY MAY BE EXERCISED AS OFTEN AS NECESSARY AND ARE CUMULATIVE AND NOT EXCLUSIVE OF RIGHTS OR REMEDIES PROVIDED BY LAW. RIGHTS UNDER THIS POLICY MAY BE WAIVED ONLY IN WRITING. DELAY IN EXERCISING OR NON-EXERCISE OF ANY SUCH RIGHT OR REMEDY DOES NOT CONSTITUTE A WAIVER OF THAT RIGHT OR REMEDY, OR ANY OTHER RIGHT OR REMEDY</p>

    <p>GOVERNING LAWS AND JURISDICTION</p>

    <p>This Privacy Policy, the Platform, the Services and the use of it is governed by the laws of India and the courts in Bangalore, in India shall have exclusive jurisdiction over any disputes connected to our Privacy Policy, Platform or the Services and your use of it.</p>

    <p>YOUR ACCEPTANCE OF THESE TERMS</p>

    <p>BY USING OR VISITING THIS PLATFORM, YOU SIGNIFY YOUR AGREEMENT OF THIS POLICY. IF YOU DO NOT AGREE TO ANY OF THESE TERMS, PLEASE DO NOT USE OUR PLATFORM OR SERVICES</p>
			</div>
		</div>
	</div>

<Footer />
	</>
	);
	}