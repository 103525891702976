import {useEffect} from "react";
import Header from "../components/Header2";
import Footer from "../components/Footer2";

export default function RefundPolicy() {

useEffect(() => {
   document.title = "Refund Policy - 10xcoder"
}, []);

return (
<>
<Header />

    <div className="container">
        <div className="row" style={{marginBottom: "96px"}}>
            <div className="col-md-3"></div>
            <div className="col mt-5">
                <h2 style={{fontWeight: "600"}}>Refund policy - 10xcoder</h2>
                <p style={{color: "#777"}}>10xcoder charges for the services on the postpaid model. Since, the services have already been delivered hence no refund can be provided for the same.</p>
                <p></p>                
            </div>
            <div className="col-md-3"></div>
        </div>          
    </div>

<Footer />
    </>
    );
    }