import { useState } from "react";
import Header from "../components/Header2";
import Footer from "../components/Footer2";
import ToggleButton from 'react-toggle-button';
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";

export default function Pricing() {


	const [toggleButton, setToggleButton] = useState(false);

	return (
		<>
			<Header />

			<div className="container pt-5" id="pricing">

				<div className="row mt-5">
					<div className="col-md-12 text-center">
						<h3>Clear and Simple</h3>
						<p>Pricing</p>
					</div>
				</div>

				<div className="row" style={{ marginBottom: "96px" }}>

					<div className="col-md-4 text-center">
						<div className="card">
							<div className="card mb-4 box-shadow">
								<div className="card-body">
									<h1 className="card-title pricing-card-title">INR 499 <small className="text-muted">/ hour</small></h1>
									<ul className="list-unstyled mt-3 mb-4 ps-5 pe-5 text-start">
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Development</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Manual QA</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> 8x5 priority support</li>
										<li><RxCrossCircled style={{ height: "24px", width: "auto", color: "red" }} /> Automated tests</li>
										<li><RxCrossCircled style={{ height: "24px", width: "auto", color: "red" }} /> Devops</li>
										<li><RxCrossCircled style={{ height: "24px", width: "auto", color: "red" }} /> Automated DB backups</li>
										<li><RxCrossCircled style={{ height: "24px", width: "auto", color: "red" }} /> Prod management</li>
										<li><RxCrossCircled style={{ height: "24px", width: "auto", color: "red" }} /> 24x7 priority support</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-4 text-center">
						<div className="card">
							<div className="card mb-4 box-shadow">
								<div className="card-body">
									<h1 className="card-title pricing-card-title">INR 999 <small className="text-muted">/ hour</small></h1>
									<ul className="list-unstyled mt-3 mb-4 ps-5 pe-5 text-start">
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Development</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Manual QA</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> 8x5 priority support</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Automated tests</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Devops</li>
										<li><RxCrossCircled style={{ height: "24px", width: "auto", color: "red" }} /> Automated DB backups</li>
										<li><RxCrossCircled style={{ height: "24px", width: "auto", color: "red" }} /> Prod management</li>
										<li><RxCrossCircled style={{ height: "24px", width: "auto", color: "red" }} /> 24x7 priority support</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-4 text-center">
						<div className="card">
							<div className="card mb-4 box-shadow">
								<div className="card-body">
									<h1 className="card-title pricing-card-title">INR 1599 <small className="text-muted">/ hour</small></h1>
									<ul className="list-unstyled mt-3 mb-4 ps-5 pe-5 text-start">
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Development</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Manual QA</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Automated tests</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Devops</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Automated DB backups</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> Prod management</li>
										<li><RxCheckCircled style={{ height: "24px", width: "auto", color: "#1bdf1b" }} /> 24x7 priority support</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>



			<Footer />
		</>
	);
}