function FontSize() {


    return (
        <>

            <div className="container">
                <div className="row">
                    <div className="col mt-5">
                        <h1 style={{ color: "red" }}>For display headings</h1>
                        <h1 class="display-1">Display 1</h1>
                        <h1 class="display-2">Display 2</h1>
                        <h1 class="display-3">Display 3</h1>
                        <h1 class="display-4">Display 4</h1>
                        <h1 class="display-5">Display 5</h1>
                        <h1 class="display-6">Display 6</h1>

                        <h1 className="mt-5" style={{ color: "red" }}>For content headings</h1>
                        <p className="h1">h1. Bootstrap heading</p>
                        <p className="h2">h2. Bootstrap heading</p>
                        <p className="h3">h3. Bootstrap heading</p>
                        <p className="h4">h4. Bootstrap heading</p>
                        <p className="h5">h5. Bootstrap heading</p>
                        <p className="h6">h6. Bootstrap heading</p>

                        <h1 className="mt-5" style={{ color: "red" }}>For content text</h1>
                        <p class="fs-1">.fs-1 text</p>
                        <p class="fs-2">.fs-2 text</p>
                        <p class="fs-3">.fs-3 text</p>
                        <p class="fs-4">.fs-4 text</p>
                        <p class="fs-5">.fs-5 text</p>
                        <p class="fs-6">.fs-6 text</p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FontSize;