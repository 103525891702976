import {useEffect} from "react";
import Header2 from "../components/Header2";
import Footer2 from "../components/Footer2";

export default function Faqs() {

useEffect(() => {
   document.title = "FAQs - 10xcoder"
}, []);

return (
<>
<Header2 />


	<div className="container">
		
		<div className="row">
			<div className="col text-center mt-5" style={{marginBottom: "48px"}}>
				<h2 style={{fontWeight: "600"}}>Frequently asked questions</h2>
				<p style={{color: "#777"}}>Here are some questions that you may have.</p>
			</div>
		</div>

		<div className="row">
			<div className="col-md-6">
				<div className="accordion" id="accordionExample">
					
					<div className="accordion-item">
						<h2 className="accordion-header" id="faq1">
						<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc1" aria-expanded="false" aria-controls="faqc1">
						How can you launch a product so fast?
						</button>
						</h2>
						<div id="faqc1" className="accordion-collapse collapse" aria-labelledby="faq1" data-bs-parent="#accordionExample">
							<div className="accordion-body">
								We have pre-build modules for common functionalities such as sign up / login, forgot password, payment gateway integration, etc which we re-use to reduce the time to market significantly.
							</div>
						</div>
					</div>

					<div className="accordion-item">
						<h2 className="accordion-header" id="faq2">
						<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc2" aria-expanded="false" aria-controls="faqc2">
						What techologies do you use?
						</button>
						</h2>
						<div id="faqc2" className="accordion-collapse collapse" aria-labelledby="faq2" data-bs-parent="#accordionExample">
							<div className="accordion-body">
								We are a polyglot team and understand that time to market is very critical for any new product. We use techologies that are best suitable to get the job done. Our team is hands-on with over 10+ tech stacks.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="faq3">
						<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc3" aria-expanded="false" aria-controls="faqc3">
						Do we have to change technologies when we are ready to scale?
						</button>
						</h2>
						<div id="faqc3" className="accordion-collapse collapse" aria-labelledby="faq3" data-bs-parent="#accordionExample">
							<div className="accordion-body">
								No. The tech choices we make are good to get you to millions of users in scale & beyond. We do not make sub-par tech choices for the sake of speed.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="faq4">
						<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc4" aria-expanded="false" aria-controls="faqc4">
						Do you work for Equity in startups?
						</button>
						</h2>
						<div id="faqc4" className="accordion-collapse collapse" aria-labelledby="faq4" data-bs-parent="#accordionExample">
							<div className="accordion-body">
								Yes, sometimes. But it depends on the size of the project. We work on equity + cash model for long term contracts. But, this requires approval on case by case basis.
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="col-md-6">
				<div className="accordion" id="accordionExample2">
					<div className="accordion-item">
						<h2 className="accordion-header" id="faq5">
						<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc5" aria-expanded="false" aria-controls="faqc5">
						Do you also help with Marketing?
						</button>
						</h2>
						<div id="faqc5" className="accordion-collapse collapse" aria-labelledby="faq5" data-bs-parent="#accordionExample2">
							<div className="accordion-body">
								No. That is not our expertise. We are called <strong>10x coder</strong> for a reason.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="faq6">
						<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc6" aria-expanded="false" aria-controls="faqc6">
						Can I get design done elsewhere?
						</button>
						</h2>
						<div id="faqc6" className="accordion-collapse collapse" aria-labelledby="faq6" data-bs-parent="#accordionExample2">
							<div className="accordion-body">
								One of our core strengths is that we understand how startups work. Our team has helped launched multiple unicons. We would encourage you to use our expertise in identifing the scope of MVPs. Post that if you wish, we are open to collaborating with another design agency.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="faq7">
						<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc7" aria-expanded="false" aria-controls="faqc7">
						Can I get only the designs done with you?
						</button>
						</h2>
						<div id="faqc7" className="accordion-collapse collapse" aria-labelledby="faq7" data-bs-parent="#accordionExample2">
							<div className="accordion-body">
								We are open to exploring different models with our customers, but would love to work the entire way with our clients.
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div className="row">
			<div className="col text-center" style={{marginBottom: "96px", marginTop: "80px"}}>
				<a href="/contact" className="btn btn-outline-primary">Want to know something else? Get in touch.</a>
			</div>
	</div>

	</div>

<Footer2 />
	</>
	);
	}