function DemoHome() {


    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col mt-5">
                        <h1>10xcoder Component Demos</h1>

                        <p className="fw-bold">Typography</p>

                        <ul>
                            <li><a href="/demos/typography/font-size">Font size</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DemoHome;