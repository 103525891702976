import { useRef, useState, useEffect } from "react";
import Header from "../components/Header2";
import Footer from "../components/Footer2";
import clsx from "clsx";
export default function Contact() {

  const subject = "Email from website";

  useEffect(() => {
    document.title = "Contact us - 10xcoder";
  }, []);

  var cust_name = useRef();
  var cust_email = useRef();
  var cust_msg = useRef();

  var [formMsgClass, setFormMsgClass] = useState(clsx("d-none"));
  var [formMsgText, setFormMsgText] = useState();

  var [sendMsgBtnClass, setSendMsgBtnClass] = useState(
    clsx("btn", "btn-primary")
  );

  function isValidEmail(email) {
    var i = email.indexOf("@");
    if (i === -1 || i === 0 || i === email.length) {
      return false;
    }
    return true;
  }

  function validatedInput(cust_name, cust_email, cust_msg) {
    if (cust_name.trim().length < 2) {
      handleError("Please enter a valid name.");
      return false;
    }

    if (!isValidEmail(cust_email.trim())) {
      handleError("Please enter a valid email ID.");
      return false;
    }

    if (cust_msg.trim().length < 10) {
      handleError("Please enter valid message.");
      return false;
    }

    return true;
  }

  function handleError(
    errorMessage = "Something went wrong. Please reach out to our team at team@10xcoder.com"
  ) {
    setFormMsgText(errorMessage);
    setFormMsgClass(clsx("alert", "alert-danger"));
    setSendMsgBtnClass(clsx("btn", "btn-primary"));
  }

  function handleSuccess() {
    clearContactForm();
    setFormMsgText(
      "We have got your message. Our team will revert back to you soon."
    );
    setFormMsgClass(clsx("alert", "alert-primary"));
    setSendMsgBtnClass(clsx("btn", "btn-primary"));
  }

  function clearContactForm() {
    cust_name.current.value = "";
    cust_email.current.value = "";
    cust_msg.current.value = "";
  }

  function sendEmail() {
    setSendMsgBtnClass(clsx("btn", "btn-primary", "disabled"));

    var testPayload = {
      cust_name: cust_name.current.value,
      cust_email: cust_email.current.value,
      cust_msg: cust_msg.current.value,
    };

    console.log(testPayload);

    if (
      validatedInput(
        cust_name.current.value,
        cust_email.current.value,
        cust_msg.current.value
      )
    ) {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var messageBody =
        cust_name.current.value +
        "\n" +
        cust_email.current.value +
        "\n" +
        cust_msg.current.value;

      let payload = {
        "subject": "Email from - 10xcoder.com website",
        "emailBody": messageBody,
        "channel": "TENX_CODER_WEBSITE"
      }

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: 'follow'
      };

      fetch("https://prod-api.10xcloud.in/v1/email/send", requestOptions)
        .then((response) => {
          if (response.ok) {
            handleSuccess();
          } else {
            handleError();
          }
        })
        .catch((error) => handleError());
    }
  }

  return (
    <>
      <Header />

      <div className="container">
        <div className="row">
          <div className="col text-center mt-5">
            <h2 style={{ fontWeight: "600" }}>Contact our team</h2>
            <p style={{ color: "#777" }}>
              You can also directly reach out to us at{" "}
              <a
                href="mailto:team@10xcoder.com"
                style={{ textDecoration: "none" }}
              >
                team@10xcoder.com
              </a>
            </p>
          </div>
        </div>

        <div className="row" style={{ marginBottom: "96px" }}>
          <div className="col-md-3"></div>
          <div className="col-md-6 mt-5">
            <form id="contactForm">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Your name"
                ref={cust_name}
                required
              />
              <br />

              <label>Email ID</label>
              <input
                type="email"
                className="form-control"
                placeholder="name@example.com"
                ref={cust_email}
                required
              />
              <br />

              <label>Message</label>
              <textarea
                className="form-control"
                placeholder="Your message"
                ref={cust_msg}
                required
              ></textarea>
              <br />

              <div className={formMsgClass} role="alert">
                {formMsgText}
              </div>

              <button
                type="button"
                className={sendMsgBtnClass}
                onClick={sendEmail}
              >
                Send Message
              </button>
            </form>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>

      <Footer />
    </>
  );
}
