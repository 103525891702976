import {useEffect} from "react";
import Header from "../components/Header2";
import Footer from "../components/Footer2";

export default function Why() {

useEffect(() => {
   document.title = "Why 10xcoder ? - 10xcoder"
}, []);

function fixCardHeight() {
	var cards = document.getElementsByClassName("whycard");

	for(var k = 0; k < cards.length; k++) {
		cards[k].style.height = "auto";
	}

	var maxHeight = -1;
	for(var i = 0; i < cards.length; i++) {
		maxHeight = Math.max(maxHeight, cards[i].offsetHeight);
	}
	// console.log(maxHeight);
	for(var j = 0; j < cards.length; j++) {
		cards[j].style.height = maxHeight + "px";
	}
}

useEffect(() => {
	fixCardHeight();
}, []);

useEffect(() => {
    window.addEventListener("resize", fixCardHeight, false);
  }, []);

return (
<>
<Header />

<div className="container">

	<div className="row">
		<div className="col text-center mt-5" style={{marginBottom:"48px"}}>
			<h2 style={{fontWeight : "600"}}>Why our customers love us?</h2>
				<p style={{color: "#777"}}>10xcoder aims for your 10xsuccess
				</p>
		</div>
	</div>

	<div className="row">
		<div className="col-md-4 mt-4">
			<div className="card cardwrap">
				<div className="card-body whycard">
					<i className="fa fa-rocket"></i>
					<h6 className="card-subtitle mb-2 text-muted">Quick</h6>
					<h4 className="card-title mb-4" style={{fontWeight: "400"}}>Launch Time</h4>
					<p className="card-text text-start" style={{color: "#777"}}>Brainstorm your idea with us and we will work with you to put a fully functional MVP in 10 weeks.</p>
				</div>
			</div>
		</div>
		<div className="col-md-4 mt-4">
			<div className="card cardwrap">
				<div className="card-body whycard">
					<i className="fa fa-trophy"></i>
					<h6 className="card-subtitle mb-2 text-muted">Proven</h6>
					<h4 className="card-title mb-4" style={{fontWeight: "400"}}>Framework</h4>
					<p className="card-text text-start" style={{color: "#777"}}>We follow agile development model used by most unicorn startups.</p>
				</div>
			</div>
		</div>
		<div className="col-md-4 mt-4">
			<div className="card cardwrap">
				<div className="card-body whycard">
					<i className="fa fa-code"></i>
					<h6 className="card-subtitle mb-2 text-muted">Avoid</h6>
					<h4 className="card-title mb-4" style={{fontWeight: "400"}}>Reinvention</h4>
					<p className="card-text text-start" style={{color: "#777"}}>In house #NoCode and #LessCode frameworks for super fast development.</p>
				</div>
			</div>
		</div>
	</div>

	<div className="row">
		<div className="col-md-4 mt-4">
			<div className="card cardwrap">
				<div className="card-body whycard">
					<i className="fa fa-info"></i>
					<h6 className="card-subtitle mb-2 text-muted">Smart</h6>
					<h4 className="card-title mb-4" style={{fontWeight: "400"}}>Analytics</h4>
					<p className="card-text text-start" style={{color: "#777"}}>Measure actions and events from day one. Build on top of insights not guess work.</p>
				</div>
			</div>
		</div>
		<div className="col-md-4 mt-4">
			<div className="card cardwrap">
				<div className="card-body whycard">
					<i className="fa fa-cloud"></i>
					<h6 className="card-subtitle mb-2 text-muted">Cloud</h6>
					<h4 className="card-title mb-4" style={{fontWeight: "400"}}>Enabled</h4>
					<p className="card-text text-start" style={{color: "#777"}}>Distributed easily scalable solutions. Can we deployed on prem or cloud.</p>
				</div>
			</div>
		</div>
		<div className="col-md-4 mt-4">
			<div className="card cardwrap">
				<div className="card-body whycard">
					<i className="fa fa-btc"></i>
					<h6 className="card-subtitle mb-2 text-muted">Advanced</h6>
					<h4 className="card-title mb-4" style={{fontWeight: "400"}}>Solutions</h4>
					<p className="card-text text-start" style={{color: "#777"}}>Out of box, multi-threaded, AI/ML and blockchain solutions.</p>
				</div>
			</div>
		</div>
	</div>

	<div className="row">
		<div className="col-md-4 mt-4">
			<div className="card cardwrap">
				<div className="card-body whycard">
					<i className="fa fa-users"></i>
					<h6 className="card-subtitle mb-2 text-muted">Hyper-Growth</h6>
					<h4 className="card-title mb-4" style={{fontWeight: "400"}}>GTM strategies</h4>
					<p className="card-text text-start" style={{color: "#777"}}>We share our experience and expertise allowing you to grow and reach a product-market fit sooner.</p>
				</div>
			</div>
		</div>
		<div className="col-md-4 mt-4">
			<div className="card cardwrap">
				<div className="card-body whycard">
					<i className="fa fa-star-half-o"></i>
					<h6 className="card-subtitle mb-2 text-muted">Experienced</h6>
					<h4 className="card-title mb-4" style={{fontWeight: "400"}}>Tech Leadership</h4>
					<p className="card-text text-start" style={{color: "#777"}}>Our tech and product ninjas will lead until you get your own CTO/CPO.</p>
				</div>
			</div>
		</div>
		<div className="col-md-4 mt-4">
			<div className="card cardwrap">
				<div className="card-body whycard">
					<i className="fa fa-usd"></i>
					<h6 className="card-subtitle mb-2 text-muted">Value</h6>
					<h4 className="card-title mb-4" style={{fontWeight: "400"}}>For money </h4>
					<p className="card-text text-start" style={{color: "#777"}}>Final cost of development is 50% less than in-house development cost.</p>
				</div>
			</div>
		</div>
	</div>

	<div className="row">
			<div className="col text-center" style={{marginBottom: "96px", marginTop: "80px"}}>
				<a href="/contact" className="btn btn-outline-primary">Need more reasons? Get in touch to experience the 10x change</a>
			</div>
	</div>

</div>

<Footer />
	</>
	);
	}