import React, {useEffect} from "react";
import Header2 from "../components/Header2";
import Footer2 from "../components/Footer2";
import {FaEbay, FaFedex} from "react-icons/fa";
import {SiDell, SiGeneralelectric, SiNike, SiNvidia, SiAmazonalexa} from "react-icons/si";
import {AiOutlineHtml5, AiOutlineMobile, AiOutlineApi, AiOutlineCloudServer} from "react-icons/ai";
import {FaQuoteLeft, FaQuoteRight} from "react-icons/fa";
import {HiOutlineLightBulb, HiOutlineCode} from "react-icons/hi";
import {VscGitPullRequestCreate} from "react-icons/vsc";
import {BiBot} from "react-icons/bi";
import { TypeAnimation } from 'react-type-animation';


function Home2 () {

	useEffect(() => {
	   document.title = "10xcoder - Home"
	}, []);

	return (
		<>
			<Header2 />

			<div className="container" id="homejumbo">
				<div className="row">

					<div className="col-md-12 text-center" style={{marginBottom: "48px"}}>

						<h1>
						  hey idea, meet execution!
						</h1>

						<h5>
						  Launch your&nbsp;
						  <TypeAnimation
						      sequence={[
						        'startup', 
						        1000, 
						        'minimum viable product', 
						        1000, 
						        'SaaS application',
						        1000,
						        'mobile application',
						        1000,
						        'web application',
						        1000
						      ]}
						      wrapper="span"
						      cursor={true}
						      repeat={Infinity}
						      style={{ color: "#777" }}
						    />
						</h5>
						<h5>
						in 10 weeks
						</h5>
						
					</div>

					<div className="col-md-12 text-center">
						<a href="/contact" className="btn btn-primary" style={{marginLeft: "8px", marginRight: "8px"}}>Get FREE consultation</a>
						<a href="/roadmap" className="btn btn-outline-primary" style={{textDecoration: "none", marginLeft: "8px", marginRight: "8px"}}>How we do it?</a>
					</div>

				</div>
			</div>

			<div className="container-fluid brands">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center mb-3">
							<h4 style={{color: "white", fontWeight: "200"}}>
								Trusted by <span style={{fontWeight: "600"}}>80</span>+ global brands in 
								<span style={{fontWeight: "600"}}> 11</span> countries to launch over 
								<span style={{fontWeight: "600"}}> 170</span> products!
							</h4>
						</div>
					
						<div className="col-md-12 text-center">
							<FaEbay />
							<SiDell />
							<FaFedex />
							<SiGeneralelectric />
							<SiNike />
							<SiNvidia />
						</div>

						<div className="col-md-12 text-center mt-3">
							<a href="/why" className="btn btn-light text-primary">Why trust us?</a>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center" style={{marginTop: "96px"}}>
						{/*<h2 style={{fontWeight: "600"}}>From deep sea robots to space shuttles</h2>
						<p style={{color: "#777"}}>Our code runs on every frontier</p>*/}
						<h2 style={{fontWeight: "600"}}>Our code runs everywhere</h2>
						<p style={{color: "#777"}}>From deep sea robots to space shuttles</p>
					</div>
				</div>
			</div>

			<div className="container" >
				<div className="row">

					<div className="col-md-4 text-center">
						<div className="card" style={{padding: "24px"}}>
						  <div className="card-body">
						  	<AiOutlineHtml5 style={{height: "48px", width: "auto", marginBottom: "24px"}} />
						    <h4 className="card-title" style={{fontWeight: "400"}}>Web Application</h4>
						    <p className="card-text" style={{color: "#777"}}>Modern responsive web apps that deliver seamless performance</p>
						  </div>
						</div>
					</div>

					<div className="col-md-4 text-center">
						<div className="card" style={{padding: "24px"}}>
						  <div className="card-body">
						  	<AiOutlineMobile style={{height: "48px", width: "auto", marginBottom: "24px"}} />
						    <h4 className="card-title" style={{fontWeight: "400"}}>Mobile Application</h4>
						    <p className="card-text" style={{color: "#777"}}>iOS or ipad apps for apple devices, android app, progressive web apps</p>
						  </div>
						</div>
					</div>

					<div className="col-md-4 text-center">
						<div className="card" style={{padding: "24px"}}>
						  <div className="card-body">
						  	<AiOutlineApi style={{height: "48px", width: "auto", marginBottom: "24px"}} />
						    <h4 className="card-title" style={{fontWeight: "400"}}>SaaS Service</h4>
						    <p className="card-text" style={{color: "#777"}}>API platforms to support millions of concurrent requests</p>
						  </div>
						</div>
					</div>

				</div>
			</div>

			<div className="container" style={{marginBottom: "48px"}}>
				<div className="row">

					<div className="col-md-4 text-center">
						<div className="card" style={{padding: "24px"}}>
						  <div className="card-body">
						  	<AiOutlineCloudServer style={{height: "48px", width: "auto", marginBottom: "24px"}} />
						    <h4 className="card-title" style={{fontWeight: "400"}}>Migrate to cloud</h4>
						    <p className="card-text" style={{color: "#777"}}>Migrate traditional applications to cloud to make them super scalable</p>
						  </div>
						</div>
					</div>

					<div className="col-md-4 text-center">
						<div className="card" style={{padding: "24px"}}>
						  <div className="card-body">
						  	<SiAmazonalexa style={{height: "48px", width: "auto", marginBottom: "24px"}} />
						    <h4 className="card-title" style={{fontWeight: "400"}}>Internet of Things</h4>
						    <p className="card-text" style={{color: "#777"}}>Smart home, smart agriculture, smart city, SCM, healthcare, wearables</p>
						  </div>
						</div>
					</div>

					<div className="col-md-4 text-center">
						<div className="card" style={{padding: "24px"}}>
						  <div className="card-body">
						  	<BiBot style={{height: "48px", width: "auto", marginBottom: "24px"}} />
						    <h4 className="card-title" style={{fontWeight: "400"}}>ML / AI Application</h4>
						    <p className="card-text" style={{color: "#777"}}>Chatbot, decision engines, prediction system, recommendation engines</p>
						  </div>
						</div>
					</div>

				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center" style={{marginBottom: "96px"}}>
						<a href="/contact" className="btn btn-outline-primary">Let's discuss your idea</a>
					</div>
				</div>
			</div>

			<div className="container-fluid quote">
				<div className="container">
					<div className="row">
						<div className="col-md-2"></div>
						<div className="col-md-8 text-center" style={{marginBottom: "96px", marginTop: "96px"}}>
							<p className="text-start mt-0 mb-0"><FaQuoteLeft style={{width: "auto", height: "48px", opacity: "50%"}} /></p>
							<blockquote className="blockquote mb-0 ps-5 pe-5">
					      		<p>We have been using 10xcoder's services for many critical projects and they have always delivered beyond expectation. They are a 10x partner in true sense. </p>
					      		<footer className="blockquote-footer text-white mt-1" style={{opacity: "50%"}}>CEO (Unicorn startup), Forbes 40 under 40</footer>
						    </blockquote>
						    <p className="text-end mt-0 mb-0"><FaQuoteRight style={{width: "auto", height: "48px", opacity: "50%"}} /></p>
						</div>
						<div className="col-md-2"></div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center" style={{marginTop: "96px"}}>
						<h2 style={{fontWeight: "600"}}>Progress over perfection</h2>
						<p style={{color: "#777"}}>To deliver fast and make iterative improvements is our philosophy</p>
						
					</div>
				</div>
			</div>

			<div className="container" >
				<div className="row" style={{marginBottom: "48px"}}>

					<div className="col-md-4 text-center" style={{marginTop: "48px"}}>
						<div className="card" style={{padding: "24px"}}>
						  <div className="card-body">
						  	<HiOutlineLightBulb style={{height: "48px", width: "auto", marginBottom: "24px"}} />
						    <h4 className="card-title" style={{fontWeight: "400"}}>Think</h4>
						    <p className="card-text" style={{color: "#777"}}>We start with 10x clear understanding of your requirements.</p>
						  </div>
						</div>
					</div>

					<div className="col-md-4 text-center" style={{marginTop: "48px"}}>
						<div className="card" style={{padding: "24px"}}>
						  <div className="card-body">
						  	<HiOutlineCode style={{height: "48px", width: "auto", marginBottom: "24px"}} />
						    <h4 className="card-title" style={{fontWeight: "400"}}>Create</h4>
						    <p className="card-text" style={{color: "#777"}}>We take 10x consistent measurable actions towards your goal.</p>
						  </div>
						</div>
					</div>

					<div className="col-md-4 text-center" style={{marginTop: "48px"}}>
						<div className="card" style={{padding: "24px"}}>
						  <div className="card-body">
						  	<VscGitPullRequestCreate style={{height: "48px", width: "auto", marginBottom: "24px"}} />
						    <h4 className="card-title" style={{fontWeight: "400"}}>Evolve</h4>
						    <p className="card-text" style={{color: "#777"}}>We improve from your feedback to become 10x better.</p>
						  </div>
						</div>
					</div>

				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center" style={{marginBottom: "96px"}}>
						<a href="/why" className="btn btn-outline-primary">Why our customers love us?</a>
					</div>
				</div>
			</div>

			<Footer2 />
		</>
	);
}

export default Home2;