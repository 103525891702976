import React from "react";

export default function Footer2() {

return (
<>
	<div className="container-fluid" id="footer">

		<div className="container">

			<div className="row">

				<div className="col-md-4 text-center" style={{marginTop: "96px"}}>
					<h5 className="mb-4">Read more</h5>
					<p><a href="/why">Why 10xcoder?</a></p>
					<p><a href="/roadmap">Roadmap</a></p>
					<p><a href="/about">About</a></p>
				</div>

				<div className="col-md-4 text-center" style={{marginTop: "96px"}}>
					<h5 className="mb-4">Have questions?</h5>
					<p><a href="/faqs">FAQs</a></p>
					
					<p><a href="/privacy-policy">Privacy Policy</a></p>
					<p><a href="/terms-and-conditions">Terms and Conditions</a></p>
				</div>

				<div className="col-md-4 text-center" style={{marginTop: "96px"}}>
					<h5 className="mb-4">Write to us</h5>
					<p><a href="mailto:team@10xcoder.com">team@10xcoder.com</a></p>
					<p><a href="https://wa.me/917027021503?text=Hey%20there" target="_blank" rel="noreferrer">Chat on whatsapp</a></p>
					<p><a href="/contact">Contact form</a></p>
				</div>

			</div>

			<div className="row">

				<div className="col-md-4 text-center" style={{marginTop: "48px"}} >
					<p>
						<a href="/home">
							<img src="assets/images/logo-white.png" className="img-fluid" style={{height:"34px"}} alt="10xcoder logo white" />
						</a>
					</p>
				</div>

				<div className="col-md-4"></div>

				<div className="col-md-4 text-center" style={{marginTop: "48px"}} >
					<p className="m-0 p-0">Made with <i className="fa fa-heart"></i> in India</p>
				</div>

			</div>
		</div>
	</div>

	
	<div style={{position: "fixed", right: "32px", bottom: "32px"}}>
		<a href="https://wa.me/917027021503?text=Hey%20there" target="_blank" rel="noreferrer">
			<img src="assets/images/whatsapp-logo.svg" style={{width:"48px", heigth: "auto", cursor: "pointer"}} alt="Chat on whatsapp button" />
		</a>
	</div>
			
</>
);
}