import {useEffect} from "react";
import Header2 from "../components/Header2";
import Footer2 from "../components/Footer2";
export default function Roadmap() {

useEffect(() => {
   document.title = "Roadmap - 10xcoder"
}, []);

return (
<>
<Header2 />

	<div className="container">
		
		<div className="row mt-5">
			<div className="col-md-3"></div>
			<div className="col-md-6 text-center" style={{paddingBottom:"48px"}}>
				<h2 style={{fontWeight : "600"}}>How <span className="">magic</span> happens?</h2>
				<p style={{color: "#777"}}>From idea to launch in 10 weeks <sup>*</sup>
				</p>
			</div>
			<div className="col-md-3"></div>
		</div>

		<div className="row" style={{marginBottom: "48px"}}>
			<div className="col-md-3"></div>
			<div className="col-md-6">
				<img src="assets/images/roadmap.png" className="img-fluid" alt="Roadmap"/>

				<p style={{marginTop: "48px", color: "#777"}}><small><sup>*</sup>Above timeline is based on the assumption that the project requirements are at least partially baked and require minimal iterations.</small></p>
			</div>
			<div className="col-md-3"></div>
		</div>
	</div>

	<div className="container">
		<div className="row">
			<div className="col-md-12 text-center" style={{marginBottom: "96px"}}>
				<a href="/contact" className="btn btn-outline-primary">Let's discuss your idea</a>
			</div>
		</div>
	</div>


<Footer2 />
	</>
	);
	}