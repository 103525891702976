import React from "react";
import {TiThMenu} from "react-icons/ti";


export default function Header2() {

return (
<>
  <nav className="navbar navbar-expand-lg">
      <div className="container">
          <a className="navbar-brand" href="/#">
            <img src="assets/images/logo.png" alt="10xcoder logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" 
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
          aria-expanded="false" aria-label="Toggle navigation">
            <TiThMenu style={{color: "#0d6efd"}} />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item"><a className="nav-link text-center" href="/home">Home</a></li>
                  <li className="nav-item"><a className="nav-link text-center" href="/roadmap">Roadmap</a></li>
{/*                  <li className="nav-item"><a className="nav-link" href="/services">Services</a></li>
*/}                  <li className="nav-item"><a className="nav-link text-center" href="/why">Why us?</a></li>
                  {/*<li className="nav-item"><a className="nav-link" href="/pricing">Pricing</a></li>*/}
                  <li className="nav-item"><a className="nav-link text-center" href="/faqs">FAQs</a></li>
                  <li className="nav-item"><a className="nav-link text-center" href="/contact">Contact</a></li>
              </ul>
          </div>
      </div>
  </nav>
</>
);
}