import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home2 from "./pages/Home2";
import Why from "./pages/Why";
import Roadmap from "./pages/Roadmap";
import Faqs from "./pages/Faqs";
import Contact from "./pages/Contact";
import Pricing from "./pages/Pricing";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import RefundPolicy from "./pages/RefundPolicy";
import Services from "./pages/Services";
import About from "./pages/About";

import DemoHome from "./pages/demos/DemoHome";
import FontSize from "./pages/demos/typography/FontSize";



function App() {
        return (
                <Router>
                        <Routes>
                                <Route exact path='/' element={<Home2 />} />
                                <Route exact path='/home' element={<Home2 />} />
                                <Route exact path='/why' element={<Why />} />
                                <Route exact path='/roadmap' element={<Roadmap />} />
                                <Route exact path='/faqs' element={<Faqs />} />
                                <Route exact path='/contact' element={<Contact />} />
                                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                                <Route exact path="/about" element={<About />} />
                                <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
                                <Route exact path="/refund-policy" element={<RefundPolicy />} />
                                <Route exact path="/pricing" element={<Pricing />} />

                                <Route exact path="/demos/home" element={<DemoHome />} />
                                <Route exact path="/demos/typography/font-size" element={<FontSize />} />

                                <Route path="*" element={<Home2 />} status={404} />

                        </Routes>
                </Router>
        );
}

export default App;
